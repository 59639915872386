export const imageLoader = ({ src, width, quality }) => {
    //console.log("image loader", src, width);

    if (src.endsWith(".jpg")) {
        return src;
    }

    let size = "";
    if (width <= 128) {
        size = "th";
    } else if (width <= 640) {
        size = "sm";
    } else if (width <= 1280) {
        size = "md";
    } else {
        size = "lg";
    }

    return src + "." + size + ".jpg";
};

export const removeExt = (src) => {
    return src ? src.split(".").slice(0, -1).join(".") : src;
};

export const PdImage = ({
    src,
    alt,
    className = "object-cover object-center w-full h-full",
    style = {},
    originalSrc = "",
    enableWebp = true,
}) => {
    //console.log("pd image", src, alt, originalSrc);

    if (originalSrc && !originalSrc.startsWith("https://cdn.purpledoorfinders.com/")) {
        return <img src={originalSrc} alt={alt} className={className} style={style} />;
    }

    if (src.endsWith(".jpg")) {
        return (
            <picture>
                {enableWebp ? (
                    <source srcSet={removeExt(src) + ".webp"} type="image/webp" />
                ) : null}
                <source srcSet={removeExt(src) + ".jpg"} type="image/jpeg" />
                <img
                    src={removeExt(src) + ".jpg"}
                    alt={alt}
                    className={className}
                    style={style}
                />
            </picture>
        );
    } else {
        // support both jpg and webp, and support media queries for 128px, 640px, 1280px, 1920px
        return (
            <picture>
                {enableWebp ? (
                    <source
                        srcSet={src + ".th.webp"}
                        type="image/webp"
                        media="(max-width: 128px)"
                    />
                ) : null}
                {enableWebp ? (
                    <source
                        srcSet={src + ".sm.webp"}
                        type="image/webp"
                        media="(max-width: 640px)"
                    />
                ) : null}
                {enableWebp ? (
                    <source
                        srcSet={src + ".md.webp"}
                        type="image/webp"
                        media="(max-width: 1280px)"
                    />
                ) : null}
                {enableWebp ? <source srcSet={src + ".lg.webp"} type="image/webp" /> : null}
                <source
                    srcSet={src + ".th.jpg"}
                    type="image/jpeg"
                    media="(max-width: 128px)"
                />
                <source
                    srcSet={src + ".sm.jpg"}
                    type="image/jpeg"
                    media="(max-width: 640px)"
                />
                <source
                    srcSet={src + ".md.jpg"}
                    type="image/jpeg"
                    media="(max-width: 1280px)"
                />
                <source srcSet={src + ".lg.jpg"} type="image/jpeg" />
                <img
                    src={src + ".md.jpg"}
                    alt={alt}
                    className="object-cover object-center w-full h-full"
                />
            </picture>
        );
    }
};
